@font-face {
    font-family: "icomoon";
    src:
        url("./Icomoon/icomoon.ttf?9i6t86") format("truetype"),
        url("./Icomoon/icomoon.woff?9i6t86") format("woff"),
        url("./Icomoon/icomoon.svg?9i6t86#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
}

body {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-family: "Inter", sans-serif;
    color: rgb(34, 34, 34);
}

input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="date"],
input[type="time"],
select,
textarea,
button {
    font-family: "Inter", sans-serif;
}
