:root {
    --primary-color: #025ade;

    --widget-font: Inter, "Helvetica Neue", Helvetica, Arial, sans-serif;
    --widget-header-bg: var(--primary-color);
    --widget-header-text: white;
    --widget-button-bg: var(--primary-color);
    --widget-button-icon: white;
    --widget-icon-bg: var(--primary-color);
    --widget-icon-icon: white;
    --widget-bubble-bg: white;
    --widget-bubble-text: var(--primary-color);
    --widget-bubble-border: var(--primary-color);

    --bubble-mine-bg: #1f85ff;
    --bubble-mine-color: white;
    --bubble-your-bg: #e2e3e4;
    --bubble-your-color: black;
    --bubble-button-color: var(--primary-color);
    --bubble-button-hover: white;

    --main-width: 600px;

    --box-shadow: 0 2px 8px 1px #f1f3f8;
    --border: 1px solid #d9e1ec;
    --border-radius: 16px;
    --border-radius-small: 8px;

}
